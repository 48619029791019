function findCupcake(identifier) {
  var links = document.getElementsByTagName('link');
  for (var i = 0; i < links.length; i++) {
    if (
      links[i].rel.indexOf('stylesheet') !== -1 &&
      links[i].title === identifier
    ) {
      return true;
    }
  }
  return false;
}

function switchCupcake(version) {
  const identifier = 'cupcake-' + version;
  if (identifier && findCupcake(identifier)) {
    var links = document.getElementsByTagName('link');
    for (var i = 0; i < links.length; i++) {
      var link = links[i];
      if (link.rel.indexOf('stylesheet') !== -1 && link.title) {
        link.disabled = link.title !== identifier;
      }
    }
  }
}

const storageKey = 'cupcake-version';
const storedVersion = sessionStorage.getItem(storageKey);

let requestedVersion;
const queryParam = window.location.search.match(/cupcake\=([0-9]*)/);
if (queryParam) {
  requestedVersion = queryParam[1];
}

if (requestedVersion) {
  switchCupcake(requestedVersion);
  sessionStorage.setItem(storageKey, requestedVersion);
} else if (storedVersion) {
  switchCupcake(storedVersion);
} else {
  sessionStorage.setItem(storageKey, 2);
}
